<template>
    <div>
        <section class="header">
            <div class="header-wrapper content">
                <div class="logo">
                    <img src="/static/images/logo.svg" alt="Logo van Altavia Unite.">
                </div>
                <div class="subheader-text">
                    <h1>Sorry, deze URL is aangepast <br /> en niet meer beschikbaar</h1>
                </div>
            </div>
        </section>

        <section class="sub-section">
            <div class="sub-section-wrapper content">
                <h3>In verband met onze naamswijziging is de URL aangepast van altaviasumis.nl naar <br /><a href="https://www.altavia-unite.nl/">altavia&#8209;unite.nl</a>. Binnen 10 sec. wordt u doorgestuurd naar de juiste URL.</h3>
            </div>
        </section>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    export default {
        mounted() {
            setTimeout( () =>
            {
                window.location.href = 'https://www.altavia-unite.nl/';
            }, 10000);
        }
    };
</script>
